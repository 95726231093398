.left-sidebar{
    // background-color: var(--brand);
    max-width: 250px;
    width: 100%;
    height: calc(100vh - 60px);
    position: fixed;
    h3{
        color: white;
    }
    .left-sidebar-inner{
      max-width: 100%;
      max-height: 100%;
      .sidebar-ul li{
          list-style: none;
          padding: 0.75rem;
          border-radius: 0.375rem;
          position: relative;
          background-color:  rgba(255,255,255,0.03);
          &:hover{
              background-color:  rgba(255,255,255,0.1);
          }
          a{
              color: var(--bs-white);
              font-weight: 500;
              text-decoration: none;
          }
          &.active{
              background-color: rgba(255,255,255,0.3);
              a{
                  color: var(--bs-white);
              }
          }
      }
  }
}
@media only screen and (max-width: 768px) {
    .left-sidebar{
        left: -250px;
    }
    .left-sidebar-open{
        max-width: 100%;
        width: 100%;
        left: 0px;
        z-index: 100;
        background-color: rgba(37,37,37,0.5);
        .left-sidebar-inner{
            padding: 0px 1rem;
            max-width: 250px;
            background-color: #252525;
            .logo-block{
                display: none;
            }
        }

    }
}