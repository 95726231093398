:root{
    // --brand: #02735E;
    // --brand: #02483b;
    --brand: #11A684;
    --red: #ff5555;
    --custom-dark: #252525;
    --custom-gray: #535353;
    // --brand: #184A2c;
    // --brand-light: #DEEFE7;
    // --brand-light: #ebfdf2;
    --custom-light: #F1F8F9;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins');
*, body{
    font-family: "Poppins", sans-serif;
}
.text-brand{
    color: var(--brand) !important;
}
.btn-brand{
    background: var(--brand) !important;
    color: #fff !important;
    &:hover, &:active{
        background: var(--brand) !important;
        color: #fff !important;
    }
}
.btn-outline-brand{
    background: var(--custom-light) !important;
    color: var(--brand) !important;
    border: 2px solid var(--brand);
    &:hover, &:active{
        background: var(--brand) !important;
        color: #fff !important;
    }
}
.bg-brand{
    background: var(--brand);
}
.bg-brand-light{
    background: var(--custom-light);
}
.text-red{
    color: var(--red);
}
.fw-500{
    font-weight: 500;
}
.fw-600{
    font-weight: 600;
}
.fw-700{
    font-weight: 700;
}

.app{
    background: linear-gradient(180deg, #252525 0%, #424242 100%);
    height: 100%;
    .content-box{
        margin-left: 250px;
        &-inner{
            flex-direction: row;
            align-items: stretch;
        }
    }
}
@media only screen and (max-width: 768px) {
    .app{
        .content-box{
            margin-left: 0;
            &-inner{
                flex-direction: column;
                align-items: start;
            }
        }
    }
}
@media only screen and (max-width: 991px) {
    .app{
        .content-box{
            &-inner{
                flex-direction: column;
                align-items: start;
            }
        }
    }
}