.scrapping {
  min-height: 100vh;
  // height: 1px;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // overflow: auto;
  .page-inner{
    background: linear-gradient(180deg, #252525 0%, #424242 100%);
    min-height: 100vh;
    // height: 1px;
}
  .content-box{
    // max-width: calc(100% - 250px);
    width: 100%;
      &-inner{
        min-height: 100%;
        border-radius: 0;
        // margin: 0 0 0 1.25rem;
        background: var(--custom-light);
        height: 1px;
        overflow: auto;
        .relative-section{
          min-height: 100%;
        }
      .upload-section{
       color: var(--bs-dark);
       .upload-inner{
        max-width: 800px;
       }
      }
      .response-section{
        
        ul{
          list-style: none;
        }
      }
      .instructions-block{
        top: 35%;
        z-index: 20;
        &-inner{
          right: -350px;
          transition: all 0.3s linear ;
          &.active{
            right: 0;
            transition: all 0.3s linear ;
          }
          .btn{
            position: relative;
            right: -40px;
          }
        }
        .instruction-details{
          max-width: 350px;
          
        }
      }
      .loading {
        display: flex;
        align-items: stretch;
        justify-content: center;
        height: 100%;
  
        #loading-spinner {
          // position: absolute;
          left: 50%;
          // top: 50%;
          margin: auto;
          z-index: 1001;
          width: 80px;
          height: 80px;
          // margin: -40px 0 0 -40px;
          border-radius: 50%;
          border: 2px solid transparent;
          border-top-color:  rgb(128, 128, 128);
          -webkit-animation: PreloaderSpin 2s linear infinite;
          animation: PreloaderSpin 2s linear infinite;
          
          &:before {
            content: '';
            display: block;
            width: 60px;
            height: 60px;
            margin: 8px 0 0px 8px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: var(--brand);
            -webkit-animation: PreloaderSpin 6s linear infinite;
            animation: PreloaderSpin 6s linear infinite;
          }
          
            &:after {
            content: '';
            display: block;
            width: 40px;
            height: 40px;
            margin: -52px 0 0px 18px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color: var(--bs-black);
            -webkit-animation: PreloaderSpin 3s linear infinite;
            animation: PreloaderSpin 3s linear infinite;
          }
        }
        @keyframes PreloaderSpin {
          0%{
            transform:rotate(0deg);
          }
          100%{
            transform:rotate(360deg);
          }
        }
      }
    }
    .toast {
      position: fixed;
      bottom: 150px;
      right: 10px;
      z-index: 9;
      // border: 0;
      box-shadow: 0px 0px 15px 0px rgba(2, 72, 59, 0.3);
      // background : #11A684;
      .toast-body{
        background-color: var(--bs-white);
        // border-left: 3px solid var(--brand);
        border-radius: 0;
        &:after{
          position: absolute;
          display: block;
          content: '';
          height: 100%;
          width: 4px;
          background-color: var(--brand);
          left: 0;
          top: 0;
          border-radius: 5px;
        }
      }
    }
  }
  // .instructions {
  //   flex: 2;
  //   border-right: 0.5px solid darkgray;

  //   .heading {
  //     margin-top: 40px;
  //     font-weight: 500;
  //     font-size: 22px;
  //     color: #121d44;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //     // text-decoration: underline;
  //   }

  //   p {
  //     margin-left: 20px;
  //     margin-bottom: 5px;
  //   }
  // }

  // .middle {
  //   flex: 3;

  //   h3 {
  //     margin-top: 40px;
  //     font-weight: 700;
  //     font-size: 18px;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //     // text-decoration: underline;
  //   }

  //   .upload {
  //     display: flex;
  //     justify-content: center;
  //     flex-direction: column;
  //     align-items: center;
  //     background-color: white;
  //     width: 60%;
  //     margin: 20px 50px;
  //     border: 2px dashed #3457d5;
  //     //   border-radius: 10px;

  //     .file-input {
  //       display: flex;
  //       align-items: center;
  //       flex-direction: column;

  //       button {
  //         margin: 10px 20px;
  //         // align-self: start;
  //         width: max-content;
  //         padding: 12px;
  //         background-color: #3457d5;
  //         border: none;
  //         border-radius: 5px;
  //         color: white;
  //         font-weight: 500;
  //         font-size: 15px;
  //       }

  //       .asin-label {
  //         width: 150px;
  //         border: 1px solid rgb(85, 84, 84);
  //         background-color: white;
  //         padding: 10px;
  //         border-radius: 5px;
  //         margin-top: 20px;
  //         text-align: center;
  //       }

  //       p{
  //           font-size: 14px;
  //           color: green;
  //       }

  //       .box-p-tag{
  //         padding: 20px;
  //         color: orangered;
  //       }

  //       .error{
  //           color: red;
  //       }

  //       .icon {
  //         height: 50px;
  //         width: 50px;
  //       }
  //     }
  //   }


  //   .error {
  //     color: red;
  //   }

  //   .response {
  //     text-align: center;
  //     color: green;
  //   }

  //   .asin{

  //     margin-left: 20px;

  //     p{
  //       font-size: 15px;
  //       font-weight: 500;
  //       color: #121d44;

  //       span{
  //         color: rgb(78, 41, 26);
  //       }
  //     }

  //     button{
  //       padding: 5px;
  //       background-color: green;
  //       border: none;
  //       border-radius: 5px;
  //       color: white;
  //       font-weight: 500;
  //       font-size: 15px;
  //       margin: 5px 0px;
  //       cursor: pointer;
  //     }

  //     .asin-text{
  //       color: black;
  //       font-weight: 400;
  //     }
  //   }


  //   .review-count{

  //     margin: 0px 40px;
  //     padding: 10px;
  //     // border: 1px solid rgb(65, 64, 64);
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     margin-top: 20px;
  //     // width: max-content;

  //     h4{
  //     color: #121d44;
  //     // color: rgb(10, 27, 102);
  //       margin-bottom: 10px;
  //     }
  //     p{
  //       padding: 5px;
  //     }

  //     .star5{

  //       display: flex;
  //       align-items: center;
  //       color:rgb(8, 58, 12);

  //       .star{
  //         font-size: 15px;
  //         color:rgb(8, 58, 12);
  //       }
  //     }.star4{

  //       display: flex;
  //       align-items: center;
  //       color:rgb(8, 58, 12);

  //       .star{
  //         font-size: 15px;
  //         color:rgb(8, 58, 12);
  //       }
  //     }.star3{

  //       display: flex;
  //       align-items: center;
  //       color:rgb(82, 57, 11);

  //       .star{
  //         font-size: 15px;
  //         color:rgb(82, 57, 11);
  //       }
  //     }.star2{

  //       display: flex;
  //       align-items: center;
  //       color:rgb(97, 47, 13);

  //       .star{
  //         font-size: 15px;
  //         color:rgb(97, 47, 13);
  //       }
  //     }
  //     .star1{

  //       display: flex;
  //       align-items: center;
  //       color:rgb(82, 19, 11);

  //       .star{
  //         font-size: 15px;
  //         color:rgb(82, 19, 11);
  //       }
  //     }
  //   }
  // }

  // .benefit {
  //   display: flex;
  //   border-left: 0.5px solid darkgray;
  //   flex: 2;
  //   // width: 100%;
  //   // justify-content: center;
  //   flex-direction: column;
  //   // overflow: scroll;

  //   p {
  //     font-weight: 700;
  //     margin-top: 40px;
  //     color: rgb(23, 58, 7);
  //     font-weight: 500;
  //     font-size: 22px;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //     // text-decoration: underline;
  //   }
  //   ul {
  //     text-decoration: none;
  //     list-style-type: none;

  //     li {
  //       text-decoration: none;
  //       margin: 15px;
  //       font-size: 15px;
  //     }
  //   }
  // }
}
@media only screen and (max-width: 768px) {
  .scrapping {
    .page-inner{
      min-height: calc(100vh - 60px);
    }
    .content-box{
      width: 100%;
      .instructions-block{
        top: 35vh;
        font-size: 14px;
        &-inner{
          .btn{
            font-size: 14px;
            right: -34px;
          }
          right: -300px;
        }
        .instruction-details{
          max-width: 300px;
          
        }
      }
    }
  }

}

@media (max-width: 575px) {
  .scrapping {
    .content-box{
      font-size: 14px;
      .upload-section .upload-inner{
        .form-control, .btn{
          font-size: 12px;
        }
      }
      .instructions-block{
        font-size: 12px;
        &-inner{
          .btn{
            font-size: 12px;
            right: -32px;
          }
          right: -200px;
        }
        .instruction-details{
          max-width: 200px;
        }
      }
    }
  }
}