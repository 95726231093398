.product-description {
  min-height: 100vh;
  // height: 1px;
  // height: 100vh;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
  // overflow: auto;
  .page-inner{
    background: linear-gradient(180deg, #252525 0%, #424242 100%);
    min-height: 100vh;
    // height: 1px;
}
  .content-box{
    // max-width: calc(100% - 250px);
    width: 100%;
    &-inner{
        min-height: 100%;
        // border-radius: 1rem 1rem 0 0;
        // margin: 0 0 0 1.25rem;
        background: var(--custom-light);
        height: 1px;
        overflow: auto;
        .relative-section{
          min-height: 100%;
        }
        .upload-section{
          color: var(--bs-dark);
          z-index: 999;
          .upload-inner{
            max-width: 800px;
          }
        }
        .response-section {
          color: var(--bs-dark);
          h4,h5{
            line-height: 1.5em;
          }
          .title-div, .description-div{
            p{
              line-height: 1.7em;
            }
          }
        }
        .instructions-block{
          top: 35%;
          z-index: 20;
          &-inner{
            right: -350px;
            transition: all 0.3s linear ;
            &.active{
              right: 0;
              transition: all 0.3s linear ;
            }
            .btn{
              position: relative;
              right: -40px;
            }
          }
          .instruction-details{
            max-width: 350px;
            
          }
        }
        .loading{
          display: flex;
          align-items: stretch;
          justify-content: center;
          height: 100%;
          #loading-spinner {
            // position: absolute;
            left: 50%;
            // top: 50%;
            z-index: 1001;
            width: 80px;
            height: 80px;
            margin: auto;
            // margin: -40px 0 0 -40px;
            border-radius: 50%;
            border: 2px solid transparent;
            border-top-color:  rgb(128, 128, 128);
            -webkit-animation: PreloaderSpin 2s linear infinite;
            animation: PreloaderSpin 2s linear infinite;
            
            &:before {
              content: '';
              display: block;
              width: 60px;
              height: 60px;
              margin: 8px 0 0px 8px;
              border-radius: 50%;
              border: 2px solid transparent;
              border-top-color: var(--brand);
              -webkit-animation: PreloaderSpin 6s linear infinite;
              animation: PreloaderSpin 6s linear infinite;
            }
            
              &:after {
              content: '';
              display: block;
              width: 40px;
              height: 40px;
              margin: -52px 0 0px 18px;
              border-radius: 50%;
              border: 2px solid transparent;
              border-top-color: var(--bs-black);
              -webkit-animation: PreloaderSpin 3s linear infinite;
              animation: PreloaderSpin 3s linear infinite;
            }
          }
          @keyframes PreloaderSpin {
            0%{
              transform:rotate(0deg);
            }
            100%{
              transform:rotate(360deg);
            }
          }
        }
    }
  }
  //   .instructions {
  //   flex: 2;
  //   border-right: 0.5px solid darkgray;

  //   .heading {
  //     margin-top: 40px;
  //     font-weight: 500;
  //     font-size: 22px;
  //     color: #121d44;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //     // text-decoration: underline;
  //   }

  //   p {
  //     margin-left: 20px;
  //     margin-bottom: 5px;
  //   }
  // }

  // .middle {
  //   flex: 3;

  //   h3 {
  //     margin-top: 40px;
  //     font-weight: 700;
  //     font-size: 18px;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //     // text-decoration: underline;
  //   }

  //   .upload {
  //     display: flex;
  //     justify-content: center;
  //     flex-direction: column;
  //     align-items: center;
  //     background-color: white;
  //     width: 60%;
  //     margin: 20px 50px;
  //     border: 2px dashed #3457d5;
  //     //   border-radius: 10px;

  //     .file-input {
  //       display: flex;
  //       align-items: center;
  //       flex-direction: column;

  //       button {
  //         margin: 10px 20px;
  //         // align-self: start;
  //         width: max-content;
  //         padding: 12px;
  //         background-color: #3457d5;
  //         border: none;
  //         border-radius: 5px;
  //         color: white;
  //         font-weight: 500;
  //         font-size: 15px;
  //       }

  //       .asin-label {
  //         min-width: 250px;
  //         border: 1px solid rgb(85, 84, 84);
  //         background-color: white;
  //         padding: 10px;
  //         border-radius: 5px;
  //         margin-top: 20px;
  //         text-align: center;
  //       }

  //       p {
  //         font-size: 14px;
  //         color: green;
  //       }

  //       .box-p-tag {
  //         padding: 20px;
  //         color: orangered;
  //       }

  //       .error {
  //         color: red;
  //       }

  //       .icon {
  //         height: 50px;
  //         width: 50px;
  //       }
  //     }
  //   }

  //   .error {
  //     color: red;
  //   }

  //   .response {
  //     text-align: center;
  //     color: green;
  //   }

  //   .asin {
  //     margin-left: 20px;

  //     p {
  //       font-size: 15px;
  //       font-weight: 500;
  //       color: #121d44;

  //       span {
  //         color: rgb(78, 41, 26);
  //       }
  //     }

  //     button {
  //       padding: 5px;
  //       background-color: green;
  //       border: none;
  //       border-radius: 5px;
  //       color: white;
  //       font-weight: 500;
  //       font-size: 15px;
  //       margin: 5px 0px;
  //       cursor: pointer;
  //     }

  //     .asin-text {
  //       color: black;
  //       font-weight: 400;
  //     }
  //   }

  //   .review-count {
  //     margin: 0px 40px;
  //     padding: 10px;
  //     // border: 1px solid rgb(65, 64, 64);
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: center;
  //     align-items: center;
  //     margin-top: 20px;
  //     // width: max-content;

  //     h4 {
  //       color: #121d44;
  //       // color: rgb(10, 27, 102);
  //       margin-bottom: 10px;
  //     }

  //     p {
  //       font-size: 20px;
  //       font-weight: 700;
  //       color: #173a07;
  //     }
  //     span{
  //       font-weight: 500;
  //       color: rgb(18, 7, 58);
  //       margin-top: 10px;
  //       font-size: 18px;

  //     }
  //   }
  // }

  // .benefit {
  //   display: flex;
  //   border-left: 0.5px solid darkgray;
  //   flex: 2;
  //   flex-direction: column;
  //   // overflow: scroll;

  //   h4 {
  //     margin-top: 40px;
  //     font-weight: 500;
  //     font-size: 22px;
  //     color: #121d44;
  //     margin-left: 0px;
  //     height: 50px;
  //     text-align: center;
  //   }

  //   .content {
  //     height: 100%;
  //     margin-left: 10px;
  //     margin-right: 10px;

  //     .top {
  //       font-weight: 400;
  //       color: rgb(18, 7, 58);
  //       font-size: 15px;
  //       // text-align: center;
  //     }

  //     ul {
  //       // text-decoration: none;
  //       // list-style-type: none;
  //       padding: 0;

  //       li {
  //         // text-decoration:double;
  //         margin: 5px;
  //         font-weight: 400;
  //         color: rgb(18, 7, 58);
  //         font-size: 15px;
  //       }
  //     }

  //     .bottom {
  //       font-weight: 400;
  //       color: rgb(18, 7, 58);
  //       margin-top: 50px;
  //       font-size: 15px;
  //       // text-align: center;
  //     }
  //   }
  // }
}
@media only screen and (max-width: 768px) {
  .product-description {
    .page-inner{
      min-height: calc(100vh - 60px);
    }
    .content-box{
      width: 100%;
      // &-inner{
      //   margin: 0 0.75rem;
      // }
      .instructions-block{
        top: 35vh;
        font-size: 14px;
        &-inner{
          .btn{
            font-size: 14px;
            right: -34px;
          }
          right: -300px;
        }
        .instruction-details{
          max-width: 300px;
          
        }
      }
    }
  }
}
@media (max-width: 575px) {
  .product-description {
    .content-box{
      font-size: 14px;
      .upload-section .upload-inner{
        z-index: 999;
        .form-control, .btn{
          font-size: 12px;
        }
      }
      .instructions-block{
        font-size: 12px;
        &-inner{
          .btn{
            font-size: 12px;
            right: -32px;
          }
          right: -200px;
        }
        .instruction-details{
          max-width: 200px;
        }
      }
    }
  }
}