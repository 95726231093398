.login{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    .card{
        width: 100%;
        max-width: 400px;
        padding: 2rem;
        // justify-content: center;
        // align-items: center;
        .icon{
            color: var(--brand);
        }
        a{
            text-decoration: none;
        }
        label{
            width: 100%;
        }

        .form-control:focus {
            color: #212529;
            background-color: var(--bs-white);
            border-color: var(--brand);
            outline:none;
            box-shadow: none;
        }

        button{
            background: var(--brand);
            border: var(--brand);
            a{
                text-decoration: none;
                color: var(--bs-white);
            }
        }

        .forgot{

            font-size: 14px;
            // margin-top: 10px;
            cursor: pointer;
            color: var(--brand);
            text-decoration: underline;
        }

        .login-failed{
            color: red;
        }
    }
}
@media only screen and (max-width: 768px) {
    .login{
        padding-left: 2rem;
        padding-right: 2rem;
        .card{
            width: 100%;
        }
  }
}