.topbar{
    display: none !important;
    width: 100%;
    height: 60px;
    background: #252525;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    box-shadow: 5px 5px 10px rgba(60,60,60,0.1);
    
    .left{
        .icon{
            font-size: 25px;
            color: var(--bs-white);   
        }
    }

    .right{
        .icon{
            color: var(--bs-white); 
            cursor: pointer;

        }

        .logout{
            position: absolute;
            right: 10px;
            background-color: var(--bs-white);
            box-shadow: -5px 5px 10px 0 rgba(60,60,60,0.1);
            padding: 10px;
            margin-top: 0.25rem;
            border-radius: 5px;
            cursor: pointer;
            z-index: 10;
            .icon{
                color: var(--red);
            }
            a{
                text-decoration: None;
            }
        }
    }
}
@media only screen and (max-width: 768px) {
    .topbar{
        display: flex !important;
        .right{
            .logout{
                display: none !important;
            }
        }
    }
}